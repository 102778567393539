export const GoogleLogo = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="48px"
      viewBox="0 0 64 64"
      width="48px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30.997,28.126l20.738,0.029C53.545,36.731,50.236,54,30.997,54C18.844,54,8.992,44.15,8.992,32s9.852-22,22.005-22	c5.708,0,10.907,2.173,14.817,5.736l-6.192,6.19c-2.321-1.988-5.329-3.196-8.625-3.196c-7.33,0-13.273,5.941-13.273,13.27	s5.942,13.27,13.273,13.27c6.156,0,10.412-3.644,11.978-8.738H30.997V28.126z" />
    </svg>
  )
}
